@import './root.css';

.top-menu {
    background-color: var(--color-one);
}
.grean-color {
    color: var(--text-grean-color) !important;
    font-weight: 600;
}
.gap-10 {
    gap: 10px;
}

.contact {
    border-right: 1px solid #d1d0cf;

}

.social-icons-container {
    flex: 0.5;
}
.flex-1-5 {
    flex: 1.5 !important;
}
.flex-0-5 {
    flex: 0.5 !important;
}
.main-menu {
    font-size: 16px;
    color: var(--text-grean-color) !important;
    text-transform: uppercase;
    display: inline-block;
    padding: 30px 0;
    line-height: 20px;
    font-weight: 500;
}

.menu-list-items {
    font-size: 16px !important;
    color: var(--text-grean-color) !important;
    text-transform: uppercase !important;
    display: inline-block !important;
    padding: 30px !important;
    line-height: 20px !important;
    font-weight: 700 !important;
}
.menu-nav-down {
    height: 100px !important; 
}
.logo-image {
    width: 170;
    height: 170px;
    z-index: 5;
    position: relative;
    top: 30%;
    transform: translateY(10%);
    /*
    border-top-left-radius: 25px;
    border-bottom-right-radius: 25px;
    box-shadow: black 2px 2px 2px;*/
}
nav.menu-nav-down div.show.navbar-collapse > ul {
    background-color: #f8f5f0;
    position: absolute;
    width: 100vh;
    height: 100vh;
    z-index: 10;
    top: 0%;
    right: 50%;
    transform: translate(50%, 0%);
    padding-top: 90px;
}
nav.menu-nav-down div.show.navbar-collapse > ul li a{
    border-bottom: solid 1px green;
}
button.navbar-toggler {
    z-index: 15;
}

@media only screen and ( max-width: 768px ){
.menuContainer {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.top-menu {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.top-menu .row > .contact, .top-menu .row > .contact + div {
 padding-left: 10px !important;
 width: 100%;
 justify-content: flex-start !important;
 font-size: 12px;
 gap: 10px;
}
.s-col {
    padding-right: 0px !important;
}
.open-time {
    font-size: 11px !important;
}
.s-row {
    gap: 10px;
    justify-content: flex-end;
}
.font-size-35 { 
    font-size: 35px;
}
.time-m, .font-size-13 {
    font-size: 13px;
}
.social-icons-container {
    display: flex;
    align-items: center;
    justify-content: center;
}
.social-icons-container > div, .top-menu {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap !important;
    align-items: center;
    justify-content: center;
}
.logo-image {
    transform: translate(-0%, -20%);
}
.navbar-toggler {
    transform: translate(0%, -100%);
}
}