.aboutus-container {
    display: flex;
    flex-direction: column;
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 100px;
    padding-right: 100px;
    text-align: left;
    gap: 30px;
    font-size: large;
}

@media (max-width: 768px) {
    .aboutus-container {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
}