@import './root.css';
.swiper-container {
    width: 90vw;
    height: 850px;
  }

  .swiper-wrapper {
    height: 45rem;
  }
  .swiper-slide {
    width: 93%;
    left: 7%;
  }
  .swiper-slide img{
    width: 100%;
    height: 100%;
    min-height: 100%;
    object-fit: cover;
  }

  .swiper-pagination {
    left: 5% !important;
  }

  .swiper-pagination-bullet {
    width: 4px;
    height: 24px;
    border-radius: 2px;
    background: #e7e7e7;
    opacity: 1;
    display: block;
    margin: 8px 0!important;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
    height: 34px;
    background: var(--text-grean-color);
}

.overlay-slide {
  width: 100%;
  height: 100%;
  background-color: rgb(6 22 46 / 20%);
  position: absolute;
}

.overlay-content {
  background: rgb(52 193 138 / 40%) !important;
    width: 40%;
    height: 50% !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 30px !important;
    border: groove 4px #39c48d !important;
    gap: 1rem;
    padding: 2rem;
}

.overlay-content .card-title {
    color: white !important;
    text-shadow: black 2px 2px 2px !important;
    font-weight: 900 !important;
    letter-spacing: 2px !important;
    font-size: 35px;
    font-family: 'circular' !important;
    border-bottom: 4px solid orangered;
}
.overlay-content .card-body .card-text {
  font-size: 20px !important;
    font-weight: 600 !important;
    letter-spacing: 1px !important;
    text-shadow: black 2px 2px 2px !important;
    color: white;
}

.read-more-btn {
  padding: 15px 60px !important;
    border-radius: 20px !important;
    background: #f8f5f0 !important;
    padding: 15px 60px !important;
    border-radius: 20px !important;
    background: #34c28a !important;
    color: white !important;
    font-size: 20px !important;
    font-weight: 900 !important;
    letter-spacing: 2px !important;
    font-family: 'circular' !important;
    box-shadow: black 2px 2px 2px;
}

@media only screen and ( max-width: 768px ){ 
  .overlay-content {
    width: 90%;
  }

  .overlay-content .card-body .card-text {
    font-size: 14px !important;
  }
  .overlay-content .read-more-btn {
    font-size: 14px !important;
    padding: 15px !important;
  }
  .swiper-vertical {
    touch-action: auto !important;
  }
}