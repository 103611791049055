.our-service-container {
    line-height: 2;
}
.our-service-container a {
    text-decoration: none;
    color: #0b3d2c;
    font-weight: bold;
}
.our-service-container a:hover {
    color: green;
}
.ourservices-img-container {
    display: flex;
    justify-content: space-between;
    padding-top: 25px;
    padding-bottom: 25px;
}
.ourservices-img-container:first-child {
    width: 50%;
}
.ourservices-img-container div {
    flex: 1;
}
.ourservices-img-container img {
    height: 350px;
    border-radius: 25px;
    box-shadow: gray 5px 5px 10px;;
    width: 100%;
    object-fit: cover !important;
}

@media (max-width: 768px) {
    .our-service-container {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    .ourservices-img-container {
        flex-direction: column !important;
    }
}