.contact-container {
    width: 60%;
    margin: auto;
    background-color: #f8f8f8;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding-top: 100px;
    padding-bottom: 100px;

  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .contact-form label {
    margin-bottom: 8px;
    color: #0b3d2c; /* Use your primary color */
  }
 
  .contact-form input,
  .contact-form textarea {
    padding: 8px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 60%;
    margin: auto;
  }
  
  .contact-form button {
    padding: 10px;
    background-color: #0b3d2c; /* Use your primary color */
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 60%;
    margin: auto;
  }
  
  .contact-form button:hover {
    background-color: #085e42; /* Darken the color on hover */
  }
  @media (max-width: 768px) {
    .contact-container {
       width: 80%;
    }
}